(() => {
	const INSTANCES = [];

	class GoToTop {
		constructor(id, params) {
			INSTANCES.push(this);
			this.id = id;
			this.showOnContentHeight = 0.4;
			this.params = params;
			this.inProgress = false;
			document.addEventListener('DOMContentLoaded', this.init.bind(this));
		}

		static get INSTANCES() {
			return INSTANCES;
		}

		init() {
			this.container = document.getElementById(this.id);
			this.speed = this.params.speed;
			this.container.addEventListener('click', this.scrollToTop.bind(this));
			this.checkScrollPosition();
			window.addEventListener('scroll', this.checkScrollPosition.bind(this));
			document.addEventListener('mousewheel', this.tryStopScroll.bind(this));
		}

		scrollToTop() {
			if (!this.inProgress) {
				this.inProgress = true;
				this.scrollInterval = setInterval(() => {
					window.scrollTo(0, 0);
					this.stopScroll();
				}, 15);
			}
		}

		stopScroll() {
			this.inProgress = false;
			clearInterval(this.scrollInterval);
		}

		tryStopScroll() {
			if (this.inProgress) { this.stopScroll(); }
		}

		checkScrollPosition() {
			if (window.pageYOffset / document.body.clientHeight > this.showOnContentHeight) { this.container.classList.add('show'); } else { this.container.classList.remove('show'); }
		}
	}

	if (window.GoToTop === undefined) { window.GoToTop = GoToTop; } else { throw new Error('ERROR: GoToTop call multiple times'); }
})();
